import React from 'react';
import './Project1.css'; // Assuming you create custom styles

const Project1 = () => {
  return (
    <section className="project-detail py-5">
      <div className="container">
        <h2 className="display-4 text-center">Project 1: Tech Startup Website</h2>
        <p className="lead text-center mb-5">
          A modern, responsive website designed to showcase the innovative solutions of a rising tech startup.
        </p>

        <div className="row mb-5">
          <div className="col-md-7">
            <h3>Project Overview</h3>
            <p>
              This project involved the complete design and development of a website for a tech startup specializing in cutting-edge solutions. The goal was to create a platform that not only conveys the startup's innovation but also provides a seamless user experience across devices.
            </p>
            <p>
              We collaborated closely with the client to understand their vision, ensuring the final product reflects their brand identity and business goals.
            </p>
          </div>
          <div className="col-md-5">
            <img src="./assets/item-1.jpg" alt="Project 1" className="img-fluid rounded shadow" />
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-5">
            <img src="./assets/item-2.jpg" alt="Project 1 Design" className="img-fluid rounded shadow" />
          </div>
          <div className="col-md-7">
            <h3>Key Features & Technologies</h3>
            <ul>
              <li><strong>Responsive Design:</strong> Optimized for all devices, ensuring a consistent user experience on desktops, tablets, and smartphones.</li>
              <li><strong>Clean User Interface:</strong> A minimalistic design approach that focuses on usability and intuitive navigation.</li>
              <li><strong>Latest Web Technologies:</strong> Built with HTML5, CSS3, and JavaScript, utilizing frameworks such as React.js for dynamic content.</li>
              <li><strong>SEO Optimized:</strong> Implemented best practices for search engine optimization to increase visibility.</li>
              <li><strong>Performance Focused:</strong> Emphasized speed and efficiency, achieving high scores in performance testing tools.</li>
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 text-center">
            <h3>Challenges & Solutions</h3>
            <p>
              One of the main challenges was integrating advanced features while maintaining a simple and clean design. Our solution was to use modular components that could be easily managed and updated, ensuring the site remains flexible and scalable as the startup grows.
            </p>
            <p>
              Additionally, we focused on optimizing load times by minimizing assets and leveraging modern web optimization techniques.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Project1;
