import React from 'react';
import './WebsiteDevelopment.css'; // Assuming you create custom styles

const WebsiteDevelopment = () => {
  return (
    <section className="service-detail py-5">
      <div className="container">
        <h2 className="display-4 text-center">Website Development</h2>
        <p className="lead text-center mb-5">
          Creating modern, scalable, and interactive websites tailored to your business needs.
        </p>

        <div className="row mb-5">
          <div className="col-md-6">
            <h3>Our Expertise</h3>
            <p>
              We offer comprehensive website development services that cover everything from front-end design to back-end integration. Our expertise includes:
            </p>
            <ul>
              <li>Responsive Design</li>
              <li>E-commerce Solutions</li>
              <li>Content Management Systems (CMS)</li>
              <li>Custom Web Applications</li>
              <li>Website Optimization for Speed and SEO</li>
            </ul>
          </div>
          <div className="col-md-6">
            <img src="/assets/website-development.jpg" alt="Website Development" className="img-fluid rounded" />
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-6">
            <img src="/assets/web-projects-showcase.jpg" alt="Our Projects" className="img-fluid rounded" />
          </div>
          <div className="col-md-6">
            <h3>Our Projects</h3>
            <p>
              We have developed websites for a diverse range of industries, including e-commerce, education, healthcare, and more. Our portfolio includes:
            </p>
            <ul>
              <li>Custom e-commerce platforms with secure payment gateways</li>
              <li>Interactive educational portals</li>
              <li>Content-rich corporate websites</li>
              <li>Responsive portfolio sites</li>
            </ul>
            <a href="/portfolio" className="btn btn-primary mt-3">View Our Portfolio</a>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-12 text-center">
            <h3>Our Development Process</h3>
            <p>
              We follow a structured process to ensure your website is delivered on time and within budget.
            </p>
          </div>
          <div className="col-md-4">
            <h4>1. Planning & Strategy</h4>
            <p>
              We begin by understanding your business goals and audience, creating a detailed plan and wireframe for your website.
            </p>
          </div>
          <div className="col-md-4">
            <h4>2. Design & Development</h4>
            <p>
              Our design team creates a visually stunning and user-friendly interface, while our developers ensure it functions flawlessly across all devices.
            </p>
          </div>
          <div className="col-md-4">
            <h4>3. Testing & Launch</h4>
            <p>
              We conduct thorough testing to eliminate bugs and optimize performance before launching your site with a tailored marketing strategy.
            </p>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-6">
            <h3>Client Testimonials</h3>
            <p>"NexWebster transformed our online presence with a website that's both beautiful and functional. Their team was professional, responsive, and easy to work with." - <strong>Sara Lee, E-commerce Business Owner</strong></p>
            <p>"The custom web application they developed has streamlined our operations and boosted our efficiency significantly." - <strong>James Peterson, Logistics Manager</strong></p>
          </div>
          <div className="col-md-6">
            <img src="/assets/client-testimonials-web.jpg" alt="Client Testimonials" className="img-fluid rounded" />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 text-center">
            <h3>Ready to Elevate Your Online Presence?</h3>
            <p>Contact us today to discuss your website development needs and let's build something great together.</p>
            <a href="/contact" className="btn btn-lg btn-success mt-3">Get a Free Consultation</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WebsiteDevelopment;
