import React from 'react';
import './GameDevelopment.css'; // Assuming you create custom styles

const GameDevelopment = () => {
  return (
    <section className="service-detail py-5">
      <div className="container">
        <h2 className="display-4 text-center">Game Development</h2>
        <p className="lead text-center mb-5">
          Crafting immersive and engaging games for mobile and desktop platforms.
        </p>

        <div className="row mb-5">
          <div className="col-md-6">
            <h3>Our Expertise</h3>
            <p>
              We specialize in developing a wide range of games, from casual mobile games to complex multiplayer experiences. Our team is proficient in various game engines like Unity, Unreal Engine, and Godot, ensuring that your game is not only fun but also technically robust.
            </p>
            <ul>
              <li>2D and 3D game development</li>
              <li>AR/VR experiences</li>
              <li>Cross-platform compatibility</li>
              <li>Game monetization strategies</li>
              <li>Post-launch support and updates</li>
            </ul>
          </div>
          <div className="col-md-6">
            <img src="/assets/game-development.jpg" alt="Game Development" className="img-fluid rounded" />
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-6">
            <img src="/assets/game-projects-showcase.jpg" alt="Our Projects" className="img-fluid rounded" />
          </div>
          <div className="col-md-6">
            <h3>Our Projects</h3>
            <p>
              We have developed games across various genres, including puzzle games, strategy games, and action-packed adventures. Our portfolio showcases a variety of successful projects that have garnered positive reviews and a loyal player base.
            </p>
            <a href="/portfolio" className="btn btn-primary mt-3">View Our Portfolio</a>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-12 text-center">
            <h3>Our Development Process</h3>
            <p>
              Our game development process is designed to bring your ideas to life efficiently and effectively.
            </p>
          </div>
          <div className="col-md-4">
            <h4>1. Concept & Planning</h4>
            <p>
              We start by understanding your vision and crafting a detailed plan, including concept art, storyboarding, and gameplay mechanics.
            </p>
          </div>
          <div className="col-md-4">
            <h4>2. Design & Development</h4>
            <p>
              Our designers and developers collaborate to create visually stunning and technically sound games, ensuring a seamless gaming experience.
            </p>
          </div>
          <div className="col-md-4">
            <h4>3. Testing & Launch</h4>
            <p>
              We rigorously test the game to eliminate bugs and optimize performance, followed by a strategic launch to reach your target audience.
            </p>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-6">
            <h3>Client Testimonials</h3>
            <p>"The game NexWebster developed for us was a huge success! The team's creativity and attention to detail were exceptional." - <strong>Mark Johnson, Game Studio Owner</strong></p>
            <p>"Their ability to deliver on time and within budget while maintaining high quality is truly commendable." - <strong>Emily Brown, Indie Game Developer</strong></p>
          </div>
          <div className="col-md-6">
            <img src="/assets/client-testimonials.jpg" alt="Client Testimonials" className="img-fluid rounded" />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 text-center">
            <h3>Ready to Develop Your Game?</h3>
            <p>Contact us today to discuss your game development needs and let's turn your ideas into a successful game.</p>
            <a href="/contact" className="btn btn-lg btn-success mt-3">Request a Free Consultation</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GameDevelopment;
