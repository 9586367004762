import React from 'react';

const LetsCollaborate = () => {
  return (
    <section className="py-5">
      <div className="container">
        <h2 className="display-4 mb-4">Let’s Collaborate</h2>
        <p>At NexWebster, we believe in building long-term relationships with our clients by delivering excellence in every project we undertake. We value collaboration and work closely with our clients to ensure their needs are met.</p>
        <p>Let's work together to bring your ideas to life and achieve great success.</p>
      </div>
    </section>
  );
};

export default LetsCollaborate;
