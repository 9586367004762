import React from 'react';

const WhyChooseUs = () => {
  return (
    <section className="py-5 bg-light">
      <div className="container">
        <h2 className="display-4 mb-4 text-center">Why Choose Us?</h2>
        <div className="row">
          <div className="col-md-6">
            <p>
              At NexWebster, we understand that in the digital age, simply having a product that works isn't enough. What sets us apart is our unwavering commitment to delivering exceptional <strong>User Interface (UI)</strong> and <strong>User Experience (UX)</strong> design. We don’t just build products; we create experiences that resonate with users and drive engagement.
            </p>
            <p>
              Our design philosophy centers around the user. We ensure that every interaction is intuitive, every feature is accessible, and every design is aesthetically pleasing. This focus on the end-user experience results in products that are not only functional but also delightful to use.
            </p>
          </div>
          <div className="col-md-6">
            <p>
              Choosing NexWebster means choosing a partner who is as invested in your success as you are. Our team is passionate about technology and dedicated to excellence. We bring a wealth of experience, a keen eye for detail, and a relentless drive for innovation to every project we undertake.
            </p>
            <p>
              We believe in building long-term relationships with our clients by consistently delivering results that exceed expectations. When you work with us, you’re not just getting a service provider; you’re getting a dedicated team that’s committed to turning your vision into reality.
            </p>
          </div>
        </div>

        <div className="mt-5 text-center">
          <a href="#projects" className="btn btn-primary btn-lg mr-3">See Our Work</a>
          <a href="#contact" className="btn btn-outline-primary btn-lg">Get In Touch</a>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
