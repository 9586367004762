// HomePage.js
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HeroSection from './../components/HeroSection';
import Introduction from './../components/Introduction';
import OurProducts from './../components/OurProducts';
import Services from './../components/Services';
import Portfolio from './../components/Portfolio';
import WhyChooseUs from './../components/WhyChooseUs';
import Footer from './../components/Footer';

function HomePage() {
  return (
      <div>
        <HeroSection />
        <Services />
        <Portfolio />
        <Introduction />
        <WhyChooseUs />
        <Footer />
      </div>
  );
}

export default HomePage;
