import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './Navbar'; // Import the Navbar component
import Header from './Header'; // Import the Navbar component
import './HeroSection.css';

const createRandomShape = () => {
  const shapeTypes = ['circle'];
  const shapeType = shapeTypes[Math.floor(Math.random() * shapeTypes.length)];
  const size = Math.random() * 100 + 200;
  const top = Math.random() * 100;
  const left = Math.random() * 100;
  const duration = Math.random() * 20 + 20 + 's';
  const delay = Math.random() + 's';

  return {
    shapeType,
    size,
    top,
    left,
    duration,
    delay
  };
};

const HeroSection = () => {
  useEffect(() => {
    const numShapes = 2; // Increased number of shapes for more dynamic visuals
    const heroBg = document.querySelector('.hero-bg');

    for (let i = 0; i < numShapes; i++) {
      const shapeData = createRandomShape();
      const shape = document.createElement('div');
      shape.className = `shape ${shapeData.shapeType}`;
      shape.style.width = shape.style.height = `${shapeData.size}px`;
      shape.style.top = `${shapeData.top}%`;
      shape.style.left = `${shapeData.left}%`;
      shape.style.animationDuration = shapeData.duration;
      shape.style.animationDelay = shapeData.delay;
      heroBg.appendChild(shape);
    }
  }, []);

  return (
    <div id="home" className="hero-container d-flex flex-column vh-100 text-white">
      <div className="hero-bg" aria-hidden="true"></div>
      <Header />
      {/* <Navbar /> */}
      <div className="hero-container d-flex flex-column align-items-center justify-content-center vh-100 text-white">

        <div className="hero-content">
          <h1 className="company display-4">NexWebster Private Limited</h1>
          <p className="lead">Innovative IT Solutions for a Digital World</p>
          <button className="btn btn-light btn-lg">Get Started</button>
        </div>
        <div className="scroll-down-indicator">
          <a href="#next-section" className="scroll-down">▼</a> {/* Smooth Scroll Button */}
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
