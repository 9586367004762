import React from 'react';
import './WebsiteHosting.css'; // Assuming custom styles

const WebsiteHosting = () => {
  return (
    <section className="service-detail py-5">
      <div className="container">
        <h2 className="display-4 text-center">Website Hosting</h2>
        <p className="lead text-center mb-5">
          Reliable, secure, and scalable hosting solutions tailored to your business needs.
        </p>

        <div className="row mb-5">
          <div className="col-md-6">
            <h3>Our Hosting Solutions</h3>
            <p>
              We offer a range of hosting services designed to ensure your website is always online, fast, and secure. Our solutions include:
            </p>
            <ul>
              <li>Shared Hosting</li>
              <li>VPS (Virtual Private Server) Hosting</li>
              <li>Dedicated Server Hosting</li>
              <li>Cloud Hosting</li>
              <li>Managed Hosting Services</li>
            </ul>
          </div>
          <div className="col-md-6">
            <img src="/assets/website-hosting.jpg" alt="Website Hosting" className="img-fluid rounded" />
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-6">
            <img src="/assets/hosting-security.jpg" alt="Hosting Security" className="img-fluid rounded" />
          </div>
          <div className="col-md-6">
            <h3>Why Choose Our Hosting?</h3>
            <p>
              Our hosting services are designed to give you peace of mind, with features that ensure your website remains secure, fast, and always accessible:
            </p>
            <ul>
              <li><strong>99.9% Uptime Guarantee:</strong> Ensuring your website is always online.</li>
              <li><strong>24/7 Support:</strong> Our expert team is available around the clock.</li>
              <li><strong>Advanced Security:</strong> Including DDoS protection, SSL certificates, and daily backups.</li>
              <li><strong>Scalability:</strong> Easily upgrade your hosting plan as your business grows.</li>
              <li><strong>Performance Optimization:</strong> With fast servers and content delivery networks (CDNs).</li>
            </ul>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-12 text-center">
            <h3>Our Hosting Packages</h3>
            <p>
              We offer flexible hosting packages designed to meet the needs of businesses of all sizes. Whether you’re a small business just getting started or a large enterprise with demanding requirements, we have the right plan for you.
            </p>
            <a href="/hosting-packages" className="btn btn-primary mt-3">Explore Hosting Packages</a>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-6">
            <h3>Migrating to Our Hosting</h3>
            <p>
              Switching to our hosting services is easy and hassle-free. Our team will handle the migration process, ensuring your website is transferred smoothly with no downtime. We offer free migration assistance to all new customers.
            </p>
          </div>
          <div className="col-md-6">
            <img src="/assets/hosting-migration.jpg" alt="Hosting Migration" className="img-fluid rounded" />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 text-center">
            <h3>Ready to Get Started?</h3>
            <p>Contact us today to discuss your hosting needs and find the perfect solution for your business.</p>
            <a href="/contact" className="btn btn-lg btn-success mt-3">Get a Free Consultation</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WebsiteHosting;
