import React from 'react';

const OurMission = () => {
  return (
    <section className="py-5">
      <div className="container">
        <h2 className="display-4 mb-4">Our Mission</h2>
        <p>Our mission is to provide innovative and reliable IT solutions that help businesses grow and succeed in the digital world. With a focus on seamless integration and user satisfaction, we strive to make technology work for you.</p>
        <p>We are committed to delivering value to our clients and helping them achieve their goals through cutting-edge technology solutions.</p>
      </div>
    </section>
  );
};

export default OurMission;
