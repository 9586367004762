import React from 'react';

const TailoredSolutions = () => {
  return (
    <section className="py-5">
      <div className="container">
        <h2 className="display-4 mb-4">Tailored Solutions</h2>
        <p>Unlike many other companies that offer generic, one-size-fits-all solutions, we focus on tailoring each product to meet the specific needs of our clients. Our expert team of developers and designers ensures that each project is delivered with precision and creativity, elevating your digital presence to new heights.</p>
        <p>We take pride in our ability to deliver customized solutions that truly make a difference for our clients.</p>
      </div>
    </section>
  );
};

export default TailoredSolutions;
