import React from 'react';
import './Project2.css'; // Assuming you create custom styles

const Project2 = () => {
  return (
    <section className="project-detail py-5">
      <div className="container">
        <h2 className="display-4 text-center">Project 2: Task Management Mobile App</h2>
        <p className="lead text-center mb-5">
          A revolutionary mobile app designed to streamline task management with a focus on efficiency, usability, and seamless integration.
        </p>

        <div className="row mb-5">
          <div className="col-md-7">
            <h3>Project Overview</h3>
            <p>
              The Task Management Mobile App is crafted to cater to the needs of busy professionals and individuals who require an efficient way to organize and track their daily tasks. The app provides a user-friendly interface that allows for easy task creation, prioritization, and tracking, all while offering seamless integration with other productivity tools.
            </p>
            <p>
              Our approach emphasized creating a highly performant app that not only looks great but also provides a smooth user experience across various mobile platforms.
            </p>
          </div>
          <div className="col-md-5">
            <img src="./assets/item-2.jpg" alt="Project 2" className="img-fluid rounded shadow" />
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-5">
            <img src="./assets/item-3.jpg" alt="Project 2 Design" className="img-fluid rounded shadow" />
          </div>
          <div className="col-md-7">
            <h3>Key Features & Technologies</h3>
            <ul>
              <li><strong>Intuitive User Interface:</strong> Designed with the user in mind, ensuring that all features are easily accessible and straightforward to use.</li>
              <li><strong>Real-Time Syncing:</strong> Automatically syncs tasks across all devices, ensuring users have the most up-to-date information at all times.</li>
              <li><strong>Integration with Other Apps:</strong> Seamlessly integrates with calendars, email, and other productivity tools to enhance the user’s workflow.</li>
              <li><strong>Cross-Platform Compatibility:</strong> Built using React Native, allowing the app to function smoothly on both iOS and Android devices.</li>
              <li><strong>Offline Access:</strong> Allows users to manage their tasks even without an internet connection, with data syncing once connectivity is restored.</li>
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 text-center">
            <h3>Challenges & Solutions</h3>
            <p>
              One of the main challenges was ensuring real-time syncing across devices without compromising on performance. We implemented a robust backend infrastructure with efficient data handling techniques to achieve this. Additionally, we focused on optimizing the app's load time and responsiveness, particularly on lower-end devices.
            </p>
            <p>
              The integration of multiple third-party APIs also posed challenges, but through careful planning and testing, we ensured seamless functionality across all supported platforms.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Project2;
