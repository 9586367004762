import React from 'react';

const NexWebster = () => {
  return (
    <section className="py-5">
      <div className="container">
        <h2 className="display-4 mb-4 text-center">Welcome to NexWebster Private Limited</h2>
        <p className="lead text-center mb-5">Your Partner in Cutting-Edge IT Solutions</p>
        
        <div className="row">
          <div className="col-md-6">
            <p>
              At NexWebster Private Limited, we specialize in delivering a broad range of IT solutions, including <strong>Website Development</strong>, <strong>Android Applications</strong>, <strong>Game Development</strong>, and <strong>Website Hosting</strong>. Our mission is to empower businesses by providing them with the tools they need to succeed in the digital age.
            </p>
            <p>
              Our pre-built products are perfect for those who need a quick and efficient solution, while our custom development services are ideal for clients looking for a tailored approach that meets their unique business needs. From small startups to large enterprises, we have the expertise to handle projects of any scale.
            </p>
          </div>
          <div className="col-md-6">
            <p>
              What sets us apart is our commitment to quality and customer satisfaction. Our team of experienced professionals is passionate about technology and driven to deliver solutions that exceed expectations. We pride ourselves on staying ahead of industry trends, ensuring that our clients always benefit from the latest innovations.
            </p>
            <p>
              Whether you're looking to launch a new website, develop a mobile app, create an engaging game, or host your site on a reliable platform, NexWebster has the expertise and resources to help you achieve your goals.
            </p>
          </div>
        </div>

        <div className="text-center mt-5">
          <a href="#contact" className="btn btn-primary btn-lg">Contact Us Today</a>
        </div>
      </div>
    </section>
  );
};

export default NexWebster;
