// ColorPredictionGameProduct.js
import React from 'react';
import './ColorPredictionGameProduct.css';

const ColorPredictionGameProduct = () => {
    return (
        <div className="product-page">
            <div className="product-hero">
                <img src="/assets/color-prediction-game-hero.jpg" alt="Color Prediction Game" className="product-image" />
                <h1>Color Prediction Game</h1>
                <p className="product-tagline">Test your luck and prediction skills with this fun and engaging game!</p>
            </div>
            <div className="product-details">
                <h2>About the Game</h2>
                <p>
                    The Color Prediction Game is a simple, yet addictive game that challenges players to predict the
                    next color. It's perfect for casual gamers looking for a quick and fun experience.
                </p>
                <h3>Features</h3>
                <ul>
                    <li>Simple and intuitive gameplay</li>
                    <li>Randomized color sequences for endless fun</li>
                    <li>Optimized for mobile and desktop devices</li>
                </ul>
                <h3>Pricing</h3>
                <p>$4.99</p>
                <button className="btn btn-primary">Buy Now</button>
            </div>
        </div>
    );
};

export default ColorPredictionGameProduct;
