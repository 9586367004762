import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaLaptopCode, FaMobileAlt, FaPencilRuler, FaBullseye, FaHandshake } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Introduction.css'; // Importing custom CSS for additional styling

const Introduction = () => {
  return (
    <section className="introduction py-5 bg-light">
      <div className="container">
        <h2 className="text-center display-4 mb-4">About Us</h2>

        <div className="row">
          {/* First Card */}
          <div className="col-md-6 mb-4">
            <div className="card h-100 poppy-card shadow-sm">
              <div className="card-body">
                <h5 className="card-title">
                  <FaLaptopCode className="me-2 icon-highlight" /> NexWebster Private Limited
                </h5>
                <p className="card-text lead">
                  NexWebster Private Limited is a leading IT solutions provider specializing in Website Development, Android Applications, Game Development, and Website Hosting.
                  Whether you need pre-built products or fully custom-developed solutions, we've got you covered.
                </p>
                <Link to="/about/nexwebster" className="btn btn-outline-primary">Learn More</Link>
              </div>
            </div>
          </div>

          {/* Second Card */}
          <div className="col-md-6 mb-4">
            <div className="card h-100 poppy-card shadow-sm">
              <div className="card-body">
                <h5 className="card-title">
                  <FaMobileAlt className="me-2 icon-highlight" /> Why Choose Us?
                </h5>
                <p className="card-text">
                  What sets us apart from other IT companies is our commitment to delivering exceptional User Interface (UI) and User Experience (UX) design.
                  We prioritize not only the functionality of our products but also the intuitive and engaging experiences they provide to users.
                </p>
                <Link to="/about/why-choose-us" className="btn btn-outline-primary">Learn More</Link>
              </div>
            </div>
          </div>

          {/* Third Card */}
          <div className="col-md-6 mb-4">
            <div className="card h-100 poppy-card shadow-sm">
              <div className="card-body">
                <h5 className="card-title">
                  <FaPencilRuler className="me-2 icon-highlight" /> Tailored Solutions
                </h5>
                <p className="card-text">
                  Unlike many other companies that offer generic, one-size-fits-all solutions, we focus on tailoring each product to meet the specific needs of our clients.
                  Our expert team of developers and designers ensures that each project is delivered with precision and creativity, elevating your digital presence to new heights.
                </p>
                <Link to="/about/tailored-solutions" className="btn btn-outline-primary">Learn More</Link>
              </div>
            </div>
          </div>

          {/* Fourth Card */}
          <div className="col-md-6 mb-4">
            <div className="card h-100 poppy-card shadow-sm">
              <div className="card-body">
                <h5 className="card-title">
                  <FaBullseye className="me-2 icon-highlight" /> Our Mission
                </h5>
                <p className="card-text">
                  Our mission is to provide innovative and reliable IT solutions that help businesses grow and succeed in the digital world.
                  With a focus on seamless integration and user satisfaction, we strive to make technology work for you.
                </p>
                <Link to="/about/our-mission" className="btn btn-outline-primary">Learn More</Link>
              </div>
            </div>
          </div>

          {/* Fifth Card */}
          <div className="col-md-12 mb-4">
            <div className="card h-100 poppy-card shadow-sm">
              <div className="card-body">
                <h5 className="card-title">
                  <FaHandshake className="me-2 icon-highlight" /> Let’s Collaborate
                </h5>
                <p className="card-text">
                  At NexWebster, we believe in building long-term relationships with our clients by delivering excellence in every project we undertake.
                </p>
                <Link to="/about/lets-collaborate" className="btn btn-outline-primary">Learn More</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Introduction;
