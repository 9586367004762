import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Portfolio.css'; // Import custom CSS
import { Link } from 'react-router-dom';

const projects = [
  { title: 'Project 1', description: 'A cutting-edge website design for a tech startup.', image: './assets/item-1.jpg', link: '/projects/project-1' },
  { title: 'Project 2', description: 'A mobile app that revolutionizes task management.', image: './assets/item-2.jpg', link: '/projects/project-2' },
  { title: 'Project 3', description: 'An e-commerce platform with seamless user experience.', image: './assets/item-3.jpg', link: '/projects/project-3' },
];

const Portfolio = () => {
  return (
    <section className="portfolio py-5 bg-light">
      <div className="container">
        <h2 className="text-center display-4 mb-5">Our Portfolio</h2>
        <div className="row">
          {projects.map((project, index) => (
            <div key={index} className="col-md-6 col-lg-4 mb-4">
              <Link to={project.link} className="text-decoration-none">
                <div className="card shadow-sm border-0 h-100 project-card">
                  <div className="card-img-top position-relative overflow-hidden">
                    <img 
                      src={project.image} 
                      className="img-fluid project-img" 
                      alt={project.title} 
                      loading="lazy" 
                    />
                    <div className="overlay d-flex align-items-center justify-content-center">
                      <div className="text text-center">
                        <h3 className="text-white">{project.title}</h3>
                        <p className="text-white">{project.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
