// ProductDetails.js
import React from 'react';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const products = [
  { 
    id: 'product-a', 
    title: 'Product A', 
    description: 'A versatile tool designed for maximum productivity and ease of use.', 
    features: ['Feature 1', 'Feature 2', 'Feature 3'], 
    details: 'Detailed information about Product A.'
  },
  { 
    id: 'product-b', 
    title: 'Product B', 
    description: 'A security-focused product that ensures your data remains safe and secure.', 
    features: ['Feature 1', 'Feature 2', 'Feature 3'], 
    details: 'Detailed information about Product B.'
  },
  { 
    id: 'product-c', 
    title: 'Product C', 
    description: 'A performance-enhancing tool to accelerate your business growth.', 
    features: ['Feature 1', 'Feature 2', 'Feature 3'], 
    details: 'Detailed information about Product C.'
  },
  { 
    id: 'product-d', 
    title: 'Product D', 
    description: 'A cloud-based solution for seamless access and collaboration.', 
    features: ['Feature 1', 'Feature 2', 'Feature 3'], 
    details: 'Detailed information about Product D.'
  },
];

const ProductDetails = () => {
  const { productId } = useParams(); // Get the product ID from the URL

  const product = products.find(p => p.id === productId); // Find the product by ID

  if (!product) {
    return <div className="text-center py-5">Product not found.</div>;
  }

  return (
    <section className="product-details py-5">
      <div className="container">
        <h2 className="text-center mb-5">{product.title}</h2>
        <p className="lead">{product.description}</p>
        <ul className="list-unstyled">
          {product.features.map((feature, index) => (
            <li key={index}>- {feature}</li>
          ))}
        </ul>
        <p className="mt-4">{product.details}</p>
      </div>
    </section>
  );
};

export default ProductDetails;
