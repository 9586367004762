// AndroidApplications.js
import React from 'react';
import './AndroidApplications.css'; // Assuming you create custom styles

const AndroidApplications = () => {
  return (
    <section className="service-detail py-5">
      <div className="container">
        <h2 className="display-4 text-center">Android Application Development</h2>
        <p className="lead text-center mb-5">
          Empowering your business with innovative and user-centric Android apps.
        </p>

        <div className="row mb-5">
          <div className="col-md-6">
            <h3>Why Choose Us?</h3>
            <ul>
              <li>Custom-tailored Android apps that meet your business needs.</li>
              <li>Expert UI/UX design for an intuitive and engaging user experience.</li>
              <li>End-to-end development from concept to deployment.</li>
              <li>Robust backend integration and ongoing support.</li>
              <li>Cross-industry experience and technical expertise.</li>
            </ul>
          </div>
          <div className="col-md-6">
            <img src="/assets/android-app-development.jpg" alt="Android App Development" className="img-fluid rounded" />
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-6">
            <img src="/assets/android-projects-showcase.jpg" alt="Our Projects" className="img-fluid rounded" />
          </div>
          <div className="col-md-6">
            <h3>Our Projects</h3>
            <p>
              We have successfully delivered numerous Android applications across various industries, including e-commerce, healthcare, finance, and more. Our portfolio showcases a diverse range of projects that highlight our ability to bring innovative ideas to life.
            </p>
            <a href="/portfolio" className="btn btn-primary mt-3">View Our Portfolio</a>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-12 text-center">
            <h3>Our Development Process</h3>
            <p>
              We follow a comprehensive and agile development process to ensure your Android app is delivered on time and within budget.
            </p>
          </div>
          <div className="col-md-4">
            <h4>1. Discovery & Planning</h4>
            <p>
              We begin by understanding your business requirements and objectives, and then create a strategic plan to bring your vision to life.
            </p>
          </div>
          <div className="col-md-4">
            <h4>2. UI/UX Design</h4>
            <p>
              Our design team crafts user-centric interfaces that are both visually appealing and easy to navigate, ensuring a great user experience.
            </p>
          </div>
          <div className="col-md-4">
            <h4>3. Development & Testing</h4>
            <p>
              Our developers build your app using the latest technologies, followed by rigorous testing to ensure it meets our high standards of quality and performance.
            </p>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-6">
            <h3>Client Testimonials</h3>
            <p>"NexWebster's Android app development service was top-notch. Our app was delivered on time and has been a hit with our users." - <strong>John Doe, CEO of XYZ Corp</strong></p>
            <p>"Working with NexWebster was a great experience. Their team is professional, responsive, and highly skilled." - <strong>Jane Smith, Founder of ABC Startup</strong></p>
          </div>
          <div className="col-md-6">
            <img src="/assets/client-testimonials.jpg" alt="Client Testimonials" className="img-fluid rounded" />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 text-center">
            <h3>Ready to Get Started?</h3>
            <p>Contact us today to discuss your Android app development needs and let us help you create something amazing.</p>
            <a href="/contact" className="btn btn-lg btn-success mt-3">Request a Free Consultation</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AndroidApplications;
