import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage.js';
import NotFound from './pages/NotFound.js';
import ProductDetails from './pages/ProductDetails';
import ColorPredictionGameProduct from './components/ColorPredictionGameProduct';

// Import Service Components
import WebsiteDevelopment from './pages/WebsiteDevelopment';
import AndroidApplications from './pages/AndroidApplications';
import GameDevelopment from './pages/GameDevelopment';
import WebsiteHosting from './pages/WebsiteHosting';

// Import project detail components
import Project1 from './pages/Project1';
import Project2 from './pages/Project2';
import Project3 from './pages/Project3';

// Import detail components
import NexWebster from './pages/NexWebster';
import WhyChooseUs from './pages/WhyChooseUs';
import TailoredSolutions from './pages/TailoredSolutions';
import OurMission from './pages/OurMission';
import LetsCollaborate from './pages/LetsCollaborate';

const AppRoutes = () => (
  <Router>
    <Routes>
      <Route path="/" element={<HomePage />} />

      {/* Product Routes */}
      <Route path="/products/:productId" element={<ProductDetails />} />
      <Route path="/products/color-prediction-game" element={<ColorPredictionGameProduct />} />

      {/* Service Routes */}
      <Route path="/services/website-development" element={<WebsiteDevelopment />} />
      <Route path="/services/android-applications" element={<AndroidApplications />} />
      <Route path="/services/game-development" element={<GameDevelopment />} />
      <Route path="/services/website-hosting" element={<WebsiteHosting />} />

      {/* Project Detail Routes */}
      <Route path="/projects/project-1" element={<Project1 />} />
      <Route path="/projects/project-2" element={<Project2 />} />
      <Route path="/projects/project-3" element={<Project3 />} />


       {/* Detail Routes */}
       <Route path="/about/nexwebster" element={<NexWebster />} />
      <Route path="/about/why-choose-us" element={<WhyChooseUs />} />
      <Route path="/about/tailored-solutions" element={<TailoredSolutions />} />
      <Route path="/about/our-mission" element={<OurMission />} />
      <Route path="/about/lets-collaborate" element={<LetsCollaborate />} />

      

      {/* Fallback Route */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  </Router>
);

export default AppRoutes;
