// Footer.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa'; // Social media icons

// SocialMediaLink Component for social icons
const SocialMediaLink = ({ href, label, Icon }) => (
  <a href={href} className="me-3 text-light" aria-label={label} target="_blank" rel="noopener noreferrer">
    <Icon size={32} />
  </a>
);

// FooterLink Component for quick links
const FooterLink = ({ href, label }) => (
  <li><a href={href} className="text-white text-decoration-none">{label}</a></li>
);

const Footer = () => {
  const quickLinks = [
    { href: "/", label: "About" },
    { href: "/", label: "Services" },
    { href: "/", label: "Blog" },
    { href: "/", label: "Contact" },
  ];

  return (
    <footer className="bg-dark text-white py-5">
      <div className="container">
        <div className="row">
          {/* Quick Links Section */}
          <div className="col-md-4 mb-4">
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              {quickLinks.map(link => (
                <FooterLink key={link.label} href={link.href} label={link.label} />
              ))}
            </ul>
            <h4 className="mt-4">Follow Us</h4>
            <div className="d-flex">
              <SocialMediaLink href="https://facebook.com" label="Facebook" Icon={FaFacebook} />
              <SocialMediaLink href="https://twitter.com" label="Twitter" Icon={FaTwitter} />
              <SocialMediaLink href="https://linkedin.com" label="LinkedIn" Icon={FaLinkedin} />
            </div>
          </div>

          {/* Legal Information Section */}
          <div className="col-md-4 mb-4">
            <h5>Legal Information</h5>
            <p><a href="/" className="text-white text-decoration-none">Privacy Policy</a></p>
            <p><a href="/" className="text-white text-decoration-none">Terms of Service</a></p>
          </div>

          {/* Contact Details Section */}
          <div className="col-md-4">
            <h5>Contact Details</h5>
            <p className="text-white">Email: <a href="mailto:info@nexwebster.com" className="text-white text-decoration-none">info@nexwebster.com</a></p>
            <p className="text-white">Phone: <a href="tel:+1234567890" className="text-white text-decoration-none">+1234567890</a></p>
            <p className="text-white">Address: 123 Tech Street, Silicon Valley</p>
            {/* <h5>Subscribe to our Newsletter</h5>
            <form>
              <input type="email" id="email" className="form-control mb-2" placeholder="Enter your email" aria-label="Email" />
              <button type="submit" className="btn btn-primary btn-block">Subscribe</button>
            </form> */}
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className="text-center mt-4">
          <p>&copy; 2024 NexWebster Private Limited. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
