import React from 'react';
import { FaLaptopCode, FaMobileAlt, FaGamepad, FaServer } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Services.css'; // Custom CSS for additional styling

const services = [
  { icon: <FaLaptopCode />, title: 'Website Development', description: 'Custom website development services.', link: '/services/website-development' },
  { icon: <FaMobileAlt />, title: 'Android Applications', description: 'Innovative and responsive mobile apps.', link: '/services/android-applications' },
  { icon: <FaGamepad />, title: 'Game Development', description: 'High-quality game development services.', link: '/services/game-development' },
  { icon: <FaServer />, title: 'Website Hosting', description: 'Reliable and secure website hosting.', link: '/services/website-hosting' },
];

const Services = () => {
  return (
    <section className="services py-5 bg-light">
      <div className="container">
        <h2 className="text-center display-4 mb-5">Our Services</h2>
        <div className="row text-center">
          {services.map((service, index) => (
            <div key={index} className="col-md-6 col-lg-3 mb-4">
              <div className="card service-card h-100 shadow-sm border-0">
                <div className="card-body">
                  <div className="service-icon mb-3 text-primary" style={{ fontSize: '2.5rem' }}>
                    {service.icon}
                  </div>
                  <h3 className="card-title h5">{service.title}</h3>
                  <p className="card-text text-muted">{service.description}</p>
                </div>
                <div className="card-footer border-0 bg-transparent">
                  <Link to={service.link} className="btn btn-outline-primary btn-sm mb-3">Learn More</Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
