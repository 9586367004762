import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import './Header.css'; // Import your custom CSS if needed

const Header = () => {
  return (
    <div>
      <header className="bg-dark text-white p-3">
        <nav className="container navbar navbar-expand d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <img
              src="/assets/logo.webp"
              alt="NexWebster Logo"
              className="logo img-fluid"
              style={{ height: '80px' }}
            />
            <h2 className="ms-3 mb-0">NexWebster</h2>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;