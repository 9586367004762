import React from 'react';
import { FaCheckCircle, FaLock, FaUsers, FaHeadset, FaChartLine, FaBriefcase } from 'react-icons/fa'; // Additional icons
import 'bootstrap/dist/css/bootstrap.min.css';
import './WhyChooseUs.css'; // Import custom CSS

const WhyChooseUs = () => {
  return (
    <section className="why-choose-us py-5">
      <div className="container">
        <h2 className="text-center display-4 mb-5">Why Choose Us?</h2>
        <div className="row">
          {/* First Point */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card border-0 shadow-sm h-100">
              <div className="card-body text-center">
                <FaCheckCircle className="text-success mb-3 icon-animate" size={48} />
                <h5 className="card-title">Custom Development</h5>
                <p className="card-text">Tailored solutions that perfectly match your business needs.</p>
              </div>
            </div>
          </div>
          {/* Second Point */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card border-0 shadow-sm h-100">
              <div className="card-body text-center">
                <FaLock className="text-primary mb-3 icon-animate" size={48} />
                <h5 className="card-title">Reliable & Secure</h5>
                <p className="card-text">Top-notch security and reliability in all our services.</p>
              </div>
            </div>
          </div>
          {/* Third Point */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card border-0 shadow-sm h-100">
              <div className="card-body text-center">
                <FaUsers className="text-info mb-3 icon-animate" size={48} />
                <h5 className="card-title">User-Centric Design</h5>
                <p className="card-text">We focus on delivering a seamless and engaging user experience.</p>
              </div>
            </div>
          </div>
          {/* Fourth Point */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card border-0 shadow-sm h-100">
              <div className="card-body text-center">
                <FaHeadset className="text-warning mb-3 icon-animate" size={48} />
                <h5 className="card-title">24/7 Support</h5>
                <p className="card-text">Round-the-clock support to keep your business running smoothly.</p>
              </div>
            </div>
          </div>
          {/* Fifth Point */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card border-0 shadow-sm h-100">
              <div className="card-body text-center">
                <FaChartLine className="text-secondary mb-3 icon-animate" size={48} />
                <h5 className="card-title">Scalable Solutions</h5>
                <p className="card-text">Grow your business with solutions that scale with you.</p>
              </div>
            </div>
          </div>
          {/* Sixth Point */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card border-0 shadow-sm h-100">
              <div className="card-body text-center">
                <FaBriefcase className="text-danger mb-3 icon-animate" size={48} />
                <h5 className="card-title">Industry Expertise</h5>
                <p className="card-text">Years of experience across various industries.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
