import React from 'react';
import './Project3.css'; // Assuming you create custom styles

const Project3 = () => {
  return (
    <section className="project-detail py-5">
      <div className="container">
        <h2 className="display-4 text-center">Project 3: E-commerce Platform</h2>
        <p className="lead text-center mb-5">
          A comprehensive e-commerce solution that delivers a seamless shopping experience, from product discovery to checkout.
        </p>

        <div className="row mb-5">
          <div className="col-md-7">
            <h3>Project Overview</h3>
            <p>
              This e-commerce platform is designed to offer a fast, intuitive, and secure shopping experience for users across the globe. With a focus on high performance, the platform ensures that users can browse, select, and purchase products effortlessly, regardless of their device or location.
            </p>
            <p>
              Our team leveraged cutting-edge technologies to build a platform that is scalable, secure, and capable of handling high traffic volumes during peak shopping seasons.
            </p>
          </div>
          <div className="col-md-5">
            <img src="./assets/item-3.jpg" alt="Project 3" className="img-fluid rounded shadow" />
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-5">
            <img src="./assets/item-4.jpg" alt="Project 3 Features" className="img-fluid rounded shadow" />
          </div>
          <div className="col-md-7">
            <h3>Key Features & Technologies</h3>
            <ul>
              <li><strong>User-Friendly Interface:</strong> A clean, responsive design that makes navigation and shopping a breeze.</li>
              <li><strong>Advanced Search & Filter:</strong> Provides users with powerful tools to find products quickly and easily.</li>
              <li><strong>Multiple Payment Gateways:</strong> Integrated with various payment providers like PayPal, Stripe, and credit card processors to offer flexible payment options.</li>
              <li><strong>Robust Backend:</strong> Built with a scalable architecture to handle high traffic and large product catalogs efficiently.</li>
              <li><strong>Security Features:</strong> Includes SSL encryption, secure payment processing, and regular security audits to protect user data.</li>
              <li><strong>Performance Optimization:</strong> Techniques like lazy loading, caching, and CDN integration ensure fast load times.</li>
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 text-center">
            <h3>Challenges & Solutions</h3>
            <p>
              One of the major challenges was ensuring that the platform remained responsive and fast under heavy load, especially during sales events. We addressed this by implementing a robust caching strategy and using a content delivery network (CDN) to serve assets efficiently.
            </p>
            <p>
              Integrating multiple payment gateways posed complexities in ensuring a consistent user experience. We overcame this by developing a unified payment interface that abstracts the differences between providers, making the checkout process smooth and user-friendly.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Project3;
