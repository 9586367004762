// NotFound.js
import React from 'react';
import './NotFound.css'; // Import the external CSS file

const NotFound = () => {
  return (
    <div className="not-found-body">
      <div className="not-found-container">
        <div className="error">
          <h1 className="heading">404</h1>
          <p className="message">Oops! The page you are looking for does not exist.</p>
          <a href="/" className="back-home">Go Back Home</a>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
